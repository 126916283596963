import axios from "axios";
import {
  GetMovementParams,
  GetProfitsParams,
  GetSalaryParams,
} from "../interfaces/common";
import {
  CreateMovement,
  Movement,
  ProfitGrouped,
  SalaryGrouped,
  UpdateMovement,
} from "../interfaces/movement.interface";
import { setToken } from "../utils/setToken";

export const getMovements = async (
  params: GetMovementParams,
  token: string
): Promise<Movement[]> => {
  return (await axios.get("/api/v1/movement", { params, ...setToken(token) }))
    .data;
};

export const getProfits = async (
  params: GetProfitsParams,
  token: string
): Promise<ProfitGrouped[]> =>
  (await axios.get("/api/v1/movement/profits", { params, ...setToken(token) }))
    .data;

export const getSalaries = async (
  params: GetSalaryParams,
  token: string
): Promise<SalaryGrouped[]> =>
  (await axios.get("/api/v1/movement/salaries", { params, ...setToken(token) }))
    .data;

export const getIncomesByDateAndCouch = async (
  params: {
    fromDate: number;
    toDate: number;
  },
  token: string
) =>
  (
    await axios.get("/api/v1/movement/incomesGroupedByDateAndCouch", {
      params,
      ...setToken(token),
    })
  ).data;

export const getMovementById = async (
  id: string,
  token: string
): Promise<Movement> =>
  (await axios.get(`/api/v1/movement/${id}`, setToken(token))).data;

export const saveMovement = async (
  data: CreateMovement,
  token: string
): Promise<string> =>
  (await axios.post("/api/v1/movement", data, setToken(token))).data;

export const patchMovement = async (data: UpdateMovement, token: string) =>
  (await axios.patch(`/api/v1/movement/${data._id}`, data, setToken(token))).data;

export const deleteMovementById = async (id: string, token: string) =>
  (await axios.delete(`/api/v1/movement/${id}`, setToken(token))).data;
