import axios from "axios";
import { GetParams } from "../interfaces/common";
import { CreatePlan, Plan, UpdatePlan } from "../interfaces/plan.interface";
import { setToken } from "../utils/setToken";

export const getPlans = async (params: GetParams<Plan>, token: string) =>
  await axios.get("/api/v1/plan", { params, ...setToken(token) });

export const savePlan = async (
  data: CreatePlan,
  token: string
): Promise<Plan> => (await axios.post("/api/v1/plan", data, setToken(token))).data;

export const patchPlan = async (data: UpdatePlan, token: string) =>
  (await axios.patch(`/api/v1/plan/${data._id}`, data, setToken(token))).data;

export const deletePlanById = async (id: string, token: string) =>
  (await axios.delete(`/api/v1/plan/${id}`, setToken(token))).data;
