import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChart({ labels, info }) {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <Bar
      options={options}
      data={{ labels, datasets: info }}
      plugins={{
        legend: {
          position: "top",
        },
      }}
      width={600}
      height={400}
    />
  );
}

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      backgroundColor: PropTypes.string,
    })
  ),
};
