import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import useResponsive from "../../hooks/useResponsive";
import { NAV } from "../../const/config-layout";

interface Props {
  children: ReactNode;
}

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};
const SPACING = 8;

export const MainLayout: React.FC<Props> = ({ children }) => {
  const lgUp = useResponsive({ query: "up", start: "lg", end: "xl" });
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
      }}
    >
      {children}
    </Box>
  );
};
