import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Plan, UpdatePlan } from "../../interfaces/plan.interface";
import { LoadingEnum } from "../../enum/common";
import { GetParams } from "../../interfaces/common";
import { getPlans } from "../../helpers/plan";

interface PlansState {
  plansList: Plan[];
  loading: LoadingEnum;
}

const initialState: PlansState = {
  plansList: [],
  loading: LoadingEnum.Idle,
};

export const getPlansList = createAsyncThunk(
  "get/plans",
  async ({ params, token }: { params: GetParams<Plan>; token: string }) =>
    (await getPlans(params, token)).data
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    addPlan: (state: PlansState, action: PayloadAction<Plan>) => {
      const newList = [...state.plansList, action.payload].sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      state.plansList = newList;
    },
    updatePlan: (state: PlansState, action: PayloadAction<UpdatePlan>) => {
      const { _id } = action.payload;

      const index = state.plansList.findIndex((at) => at._id === _id);

      if (index === -1) {
        return;
      }

      state.plansList[index] = { ...state.plansList[index], ...action.payload };
    },
    deletePlan: (state: PlansState, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;

      const index = state.plansList.findIndex((at) => at._id === id);

      if (index === -1) {
        return;
      }

      state.plansList.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder.addCase(getPlansList.pending, (state: PlansState) => {
      state.loading = LoadingEnum.Pending;
    });
    builder.addCase(
      getPlansList.fulfilled,
      (state: PlansState, action: PayloadAction<Plan[]>) => {
        state.plansList = action.payload;
        state.loading = LoadingEnum.Success;
      }
    );
    builder.addCase(getPlansList.rejected, (state: PlansState) => {
      state.loading = LoadingEnum.Error;
    });
  },
});

export default plansSlice.reducer;

export const { addPlan, updatePlan, deletePlan } = plansSlice.actions;
