import { ReactNode, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Nav from "./Nav";
import Header from "./Header";
import { MainLayout } from "./MainLayout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAthletesList } from "../../features/athletes/athletes.slice";
import { LoadingEnum, OrderEnum, UserRole } from "../../enum/common";
import { getCouchesList } from "../../features/couches/couches.slice";
import { getPlansList } from "../../features/plans/plans.slice";
import { addNewToast } from "../../features/toast/toast.slice";
import { ErrorMsg, ToastSeverity } from "../../enum/toast.enum";
import {
  getExpensesList,
  getIncomesList,
  getPaymentsList,
} from "../../features/movements/movement.slice";

// ----------------------------------------------------------------------
interface Props {
  children: ReactNode;
}

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);
  const dispatch = useAppDispatch();
  const { loading: athletesLoading } = useAppSelector(
    (state) => state.athletes
  );
  const { loading: couchesLoading } = useAppSelector((state) => state.couches);
  const { loading: plansLoading } = useAppSelector((state) => state.plans);
  const { incomesDateRange, paymentsDateRange, expensesDateRange } =
    useAppSelector((state) => state.movements);
  const { token, user } = useAppSelector((state) => state.session);

  useEffect(() => {
    if (!token || user?.role === undefined) {
      return;
    }
    dispatch(
      getAthletesList({
        params: {
          order: OrderEnum.ASC,
          orderBy: "lastName",
          userRole: user.role,
        },
        token,
      })
    );
    dispatch(
      getCouchesList({
        params: {
          order: OrderEnum.ASC,
          orderBy: "lastName",
          userRole: user.role,
        },
        token,
      })
    );
    dispatch(
      getPlansList({
        params: {
          order: OrderEnum.ASC,
          orderBy: "name",
          userRole: user.role,
        },
        token,
      })
    );
  }, [token, dispatch, user?.role]);

  useEffect(() => {
    if (!token || user?.role === undefined) {
      return;
    }

    dispatch(
      getIncomesList({
        params: {
          order: OrderEnum.DESC,
          orderBy: "date",
          userRole: user.role,
          ...incomesDateRange,
        },
        token,
      })
    );
  }, [token, incomesDateRange, dispatch, user?.role]);

  useEffect(() => {
    if (!token || user?.role === undefined) {
      return;
    }
    dispatch(
      getPaymentsList({
        params: {
          order: OrderEnum.DESC,
          orderBy: "date",
          userRole: user.role,
          ...paymentsDateRange,
        },
        token,
      })
    );
  }, [token, paymentsDateRange, dispatch, user?.role]);

  useEffect(() => {
    if (!token || user?.role === undefined) {
      return;
    }
    dispatch(
      getExpensesList({
        params: {
          order: OrderEnum.DESC,
          orderBy: "date",
          userRole: user.role,
          ...expensesDateRange,
        },
        token,
      })
    );
  }, [token, expensesDateRange, dispatch, user?.role]);

  useEffect(() => {
    if (athletesLoading === LoadingEnum.Error) {
      dispatch(
        addNewToast({
          severity: ToastSeverity.Error,
          message: ErrorMsg.AthletesFetch,
        })
      );
    }
  }, [athletesLoading, dispatch]);

  useEffect(() => {
    if (couchesLoading === LoadingEnum.Error) {
      dispatch(
        addNewToast({
          severity: ToastSeverity.Error,
          message: ErrorMsg.CouchesFetch,
        })
      );
    }
  }, [couchesLoading, dispatch]);

  useEffect(() => {
    if (plansLoading === LoadingEnum.Error) {
      dispatch(
        addNewToast({
          severity: ToastSeverity.Error,
          message: ErrorMsg.PlansFetch,
        })
      );
    }
  }, [plansLoading, dispatch]);

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          backgroundColor: "rgb(200,237,230, 0.1)",
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <MainLayout>{children}</MainLayout>
      </Box>
    </>
  );
};

export default DashboardLayout;
