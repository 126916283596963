import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { ToastInterface } from "../../interfaces/toast.intarface";

export const MAX_TOASTS_QUEUE = 5;
export const TOAST_INTERVAL = 7000;

export interface ToastSlice {
  toasts: ToastInterface[];
}

const initialState: ToastSlice = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addNewToast: (state: ToastSlice, action: PayloadAction<ToastInterface>) => {
      const newToast = action.payload;

      if (state.toasts.find((t) => t.message === newToast.message)) {
        return;
      }

      const id = uuid();
      if (state.toasts.length === MAX_TOASTS_QUEUE) {
        state.toasts.shift();
      }

      state.toasts.push({ id, ...newToast });
    },
    removeToast: (state: ToastSlice, action: PayloadAction<string>) => {
      const index = state.toasts.findIndex(
        (toast) => toast.id === action.payload
      );
      state.toasts.splice(index, 1);
    },
  },
});

export const { addNewToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;
