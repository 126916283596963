import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { AppWidgetSummary } from "../components/UI/atoms/AppWidgetSummary";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProfitGrouped } from "../interfaces/movement.interface";
import { GetProfitsParams, MetadataInput } from "../interfaces/common";
import { getProfits } from "../helpers/movement";
import { useAppSelector } from "../hooks";
import { Icon } from "@iconify/react";
import moment from "moment";
import { isToday } from "../utils/isToday";
import {
  PickerChangeHandlerContext,
  DateValidationError,
} from "@mui/x-date-pickers";
import { InputField } from "../components/UI/atoms/InputField";
import { useToasts } from "../hooks/useToasts";
import { PieChart } from "../components/UI/molecules/PieChart";
import { LoadingEnum, SuscriptionStatus, UserRole } from "../enum/common";
import { BarChart } from "../components/UI/molecules/BarChart";
import { COLORS } from "../const/colors";
import { getMonthsArrayFromTo } from "../utils/getMonthsArrayFromTo";
import DashboardAdminSection from "../components/UI/molecules/DashboardAdminSection";
import { RunnerLoader } from "../components/UI/atoms/RunnerLoader";

type TotalTypes = "total" | "trackfit" | "couches";

export const MONTHS_BEFORE_NOW = 12;

const Dashboard = () => {
  const { statusErrorMdw } = useToasts();
  const { token, user } = useAppSelector((state) => state.session);
  const [profits, setProfits] = useState<ProfitGrouped[]>([]);
  const [resume, setResume] = useState<{ [key in TotalTypes]: number }>();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    fromDate: moment()
      .subtract(MONTHS_BEFORE_NOW, "month")
      .startOf("day")
      .valueOf(),
    toDate: moment().endOf("day").valueOf(),
  });
  const { athletesList: athletes, loading: athletesLoading } = useAppSelector(
    (state) => state.athletes
  );
  const [months, setMonths] = useState<{ month: number; year: number }[]>([]);
  const reduceAthletesInfo = useMemo(
    () =>
      athletes.reduce(
        (total, atl) => ({
          ...total,
          [atl.status]: total[atl.status] + 1,
        }),
        {
          [SuscriptionStatus.Active]: 0,
          [SuscriptionStatus.Inactive]: 0,
          [SuscriptionStatus.PendingPayment]: 0,
          [SuscriptionStatus.Late]: 0,
        }
      ),
    [athletes]
  );
  const isLoading = useMemo(
    () => athletesLoading === LoadingEnum.Pending || loading,
    [athletesLoading, loading]
  );

  const profistByMonthInfo = useMemo(() => {
    const info = [
      {
        label: "Total",
        data: [] as number[],
        backgroundColor: COLORS.green.hex,
      },
      {
        label: "Trackfit",
        data: [] as number[],
        backgroundColor: COLORS.darkblue.hex,
      },
      {
        label: "Couches",
        data: [] as number[],
        backgroundColor: COLORS.lightGreen.hex,
      },
    ];

    months.forEach((mon, monthIndex) => {
      const foundProfit = profits.find(
        (prof) => prof._id.month === mon.month + 1 && prof._id.year === mon.year
      );

      info[0].data[monthIndex] = foundProfit?.profitsTotal || 0;
      info[1].data[monthIndex] = foundProfit?.trackfitTotal || 0;
      info[2].data[monthIndex] = foundProfit?.couchTotal || 0;
    });

    if (user?.role === UserRole.Admin) {
      return info;
    }
    return [{ ...info[2], label: user?.firstName }];
  }, [months, profits, user]);

  const fetchProfits = useCallback(
    async (params: GetProfitsParams, token: string) => {
      setLoading(true);
      try {
        const data = await getProfits(params, token);
        setProfits(data);
      } catch (e: any) {
        statusErrorMdw(e);
      } finally {
        setLoading(false);
      }
    },
    [statusErrorMdw]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    setMonths(getMonthsArrayFromTo(dateRange.fromDate, dateRange.toDate));
    fetchProfits({ ...dateRange }, token);
  }, [dateRange, token]);

  useEffect(() => {
    setResume(
      profits.reduce(
        (res, profit) => {
          res["total"] = res["total"] + profit.profitsTotal;
          res["couches"] = res["couches"] + profit.couchTotal;
          res["trackfit"] = res["trackfit"] + profit.trackfitTotal;

          return res;
        },
        { total: 0, trackfit: 0, couches: 0 }
      )
    );
  }, [profits]);

  const handleChangeRangeDate = useCallback(
    (
      value: any,
      context: PickerChangeHandlerContext<DateValidationError>,
      name: string
    ) => {
      if (context.validationError || dateRange.fromDate > dateRange.toDate) {
        return;
      }
      setDateRange((dateRange) => ({
        ...dateRange,
        [name]: moment(value).valueOf(),
      }));
    },
    [dateRange]
  );

  const filterInputs: MetadataInput[] = useMemo(
    () => [
      {
        id: "fromDate",
        name: "fromDate",
        label: "Desde",
        inputType: "date",
        disabled: isLoading,
        value: dateRange.fromDate,
        handleChangeDate: handleChangeRangeDate,
        gridSize: {
          lg: 6,
          sm: 6,
          md: 4,
          xs: 12,
        },
      },
      {
        id: "toDate",
        name: "toDate",
        label: "Hasta",
        inputType: "date",
        disabled: isLoading,
        value: dateRange.toDate,
        handleChangeDate: handleChangeRangeDate,
        gridSize: {
          lg: 6,
          sm: 6,
          md: 4,
          xs: 12,
        },
      },
    ],
    [handleChangeRangeDate, dateRange, isLoading]
  );

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" sx={{ color: "rgb(12,60,108)" }}>
          Hola 👋. Estos son los datos del{" "}
          {moment(dateRange.fromDate).format("DD [de] MMMM [de] YYYY")}{" "}
          {isToday(dateRange.toDate)
            ? "a hoy"
            : "al" +
              moment(dateRange.fromDate).format("DD [de] MMMM [de] YYYY")}
          .
        </Typography>
        <Grid container>
          {isLoading && (
            <Grid item xs={12}>
              <RunnerLoader />
            </Grid>
          )}
          <Grid item xl={4} md={6}>
            <AppWidgetSummary sx={{ py: 0, justifyContent: "flex-start" }}>
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  sx={{ width: "100%" }}
                  expandIcon={<Icon icon="mdi:expand-more" />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  Modificar rango de fechas
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {filterInputs.map((input) => (
                      <Grid
                        key={input.id}
                        item
                        sm={12}
                        {...input.gridSize}
                        padding={1}
                      >
                        <InputField {...input} />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </AppWidgetSummary>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AppWidgetSummary
                    subtitle={
                      user?.role === UserRole.Admin
                        ? "Ingresos totales"
                        : `Ingresos para ${user?.firstName}`
                    }
                    total={resume?.total || 0}
                    icon={
                      <Icon
                        icon={"fa-solid:cash-register"}
                        width={"100%"}
                        color="rgb(44,188,156,0.8)"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppWidgetSummary
                    title={`Atletas (${athletes.length})`}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <PieChart
                      labels={[
                        `Activos (${
                          reduceAthletesInfo[SuscriptionStatus.Active]
                        })`,
                        `Baja (${
                          reduceAthletesInfo[SuscriptionStatus.Inactive]
                        })`,
                        `Pendientes (${
                          reduceAthletesInfo[SuscriptionStatus.PendingPayment]
                        })`,
                        `Atrasados (${
                          reduceAthletesInfo[SuscriptionStatus.Late]
                        })`,
                      ]}
                      info={Object.values(reduceAthletesInfo)}
                    />
                  </AppWidgetSummary>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <AppWidgetSummary title="Ganancias por mes">
                <BarChart
                  labels={months.map(
                    (mon) =>
                      `${moment().month(mon.month).format("MMMM")} ${mon.year}`
                  )}
                  info={profistByMonthInfo}
                />
              </AppWidgetSummary>
            </Grid>
          </Grid>
        </Grid>
        {user?.role === UserRole.Admin && (
          <DashboardAdminSection
            dateRange={dateRange}
            months={months}
            profits={profits}
            setLoading={setLoading}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;
