import React, {  MouseEventHandler } from "react";
import useResponsive from "../../hooks/useResponsive";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import { NAV } from "../../const/config-layout";
import { HEADER } from "./MainLayout";
import { Icon } from "@iconify/react";
import { AccountPopover } from "../UI/molecules/AccountPopover";

interface Props {
  onOpenNav: MouseEventHandler<HTMLButtonElement> | undefined;
}
const Header: React.FC<Props> = ({ onOpenNav }) => {
  const theme = useTheme();

  const lgUp = useResponsive({ query: "up", start: "lg", end: "xl" });
  return (
    <AppBar
      sx={{
        backgroundColor: "rgb(12,60,108)",
        borderRadius: 2,
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp
          ? {
              width: `calc(100% - ${NAV.WIDTH + 1}px)`,
              height: HEADER.H_DESKTOP,
            }
          : {}),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {!lgUp && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
            <Icon icon="eva:menu-2-fill" />
          </IconButton>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={1}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
