import { ReactNode, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { usePathname } from "../../hooks/usePathname";
import useResponsive from "../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import navConfig from "./config-navigation";
import avatar from "../../assets/images/avatars/avatar_25.jpg";
import { useAppSelector } from "../../hooks";
import { UserRole } from "../../enum/common";

// ----------------------------------------------------------------------

interface Props {
  openNav: boolean;
  onCloseNav: () => void;
}

const NAV = {
  WIDTH: 280,
};

const Nav: React.FC<Props> = ({ openNav, onCloseNav }) => {
  const pathname = usePathname();
  const { user } = useAppSelector((state) => state.session);

  const upLg = useResponsive({ query: "up", start: "lg", end: "xl" });

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        bgcolor: "rgb(44,188,156, 0.9)",
        boxShadow:
          "0px 2px 4px -1px rgba(145, 158, 171, 0.2), 0px 4px 5px 0px rgba(145, 158, 171, 0.14), 0px 1px 10px 0px rgba(145, 158, 171, 0.12)",
      }}
    >
      <Avatar src={avatar} alt="photoURL" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          {user?.firstName.split(" ")[0]}
        </Typography>

        <Typography variant="body2" sx={{ color: "white" }}>
          {user?.role !== undefined ? UserRole[user?.role] : "No role"}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig
        .filter(
          (nav) => user?.role !== undefined && nav.roles.includes(user?.role)
        )
        .map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/*   <Logo sx={{ mt: 3, ml: 4 }} /> */}

      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Nav;

// ----------------------------------------------------------------------

const NavItem: React.FC<{
  item: { title: string; path: string; icon: ReactNode };
}> = ({ item }) => {
  const pathname = usePathname();
  const nav = useNavigate();

  const active = item.path === pathname;

  return (
    <ListItemButton
      onClick={() => nav(item.path)}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#249c94",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#144f74",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
      id={`nav-item-${item.title}`}
      data-testid={`nav-item-${item.title}`}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>
      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
};
