import React, { ReactNode, useCallback, useEffect } from "react";
import { usePathname } from "../../hooks/usePathname";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";
import {
  deleteSession,
  getProfile,
  saveToken,
} from "../../features/session/session.slice";
import { addNewToast } from "../../features/toast/toast.slice";
import { ErrorMsg, ToastSeverity } from "../../enum/toast.enum";

interface Props {
  children: ReactNode;
}
export const SessionProvider: React.FC<Props> = ({ children }) => {
  const pathName = usePathname();
  const nav = useNavigate();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { expiredToken } = useAppSelector((state) => state.session);

  const verifySession = useCallback(() => {
    const savedToken = localStorage.getItem("access_token");

    if (!savedToken) {
      return false;
    }

    dispatch(saveToken({ access_token: savedToken }));
    appDispatch(getProfile(savedToken));
    return true;
  }, [appDispatch, dispatch, nav]);

  const goToLogin = useCallback(
    (errMsg: ErrorMsg = ErrorMsg.SessionNotFound) => {
      nav("/login");
      dispatch(
        addNewToast({
          severity: ToastSeverity.Error,
          message: errMsg,
        })
      );
    },
    []
  );

  useEffect(() => {
    window.addEventListener("storage", (ev: StorageEvent) => {
      if (ev.key === "access_token" && !ev.newValue) {
        goToLogin();
      }
    });

    if (!verifySession()) {
      goToLogin();
      return;
    }

    if (pathName.endsWith("login")) {
      nav("/");
    }
  }, []);

  useEffect(() => {
    if (expiredToken) {
      dispatch(deleteSession());
      goToLogin(ErrorMsg.ExpiredToken);
      return;
    }
  }, [expiredToken, dispatch, nav]);

  return <>{children}</>;
};
