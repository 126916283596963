import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Card,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useState, MouseEvent } from "react";
import { Column, MetadataTable } from "../../interfaces/common";
import _ from "lodash";

interface Props {
  entityName: string;
  data: any[];
  metadata: MetadataTable;
  loading?: boolean;
}

export const SimpleTable: React.FC<Props> = ({
  entityName,
  data,
  metadata,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const valueFactory = useCallback((col: Column<any>, info: any) => {
    if (col.compute) {
      return col.compute(info);
    }

    const value = _.get(info, col.name);

    if (value === undefined) {
      return "No data";
    }

    if (new RegExp(/price|amount|profits/gi).test(String(col.name))) {
      return `$${value}`;
    }

    return value;
  }, []);

  return (
    <Card sx={{ overflowX: "auto", width: "100%" }}>
      <TableContainer sx={{ overflowX: "auto", width: "100%" }}>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              {metadata.columns.map((col, i) => (
                <TableCell key={`header-cell-${i}-${col.label}`}>
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((info, i) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  role="button"
                  key={`row-${info._id}-${i}`}
                >
                  {metadata.columns.map((col, i) => (
                    <TableCell
                      key={`cell-${String(col.name)}-${i}-${_.get(
                        info,
                        col.name
                      )}`}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {valueFactory(col, info)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {!data.length && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={metadata.columns.length}
                  sx={{ py: 3 }}
                >
                  <Paper
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Aún no hay {entityName}s registrados
                    </Typography>

                    <Typography variant="body2">
                      Añade alguno para mostrarlo aquí
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && <LinearProgress />}
    </Card>
  );
};
