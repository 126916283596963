import moment from "moment";
import {
  IncomesGrouped,
  ReduceIncomes,
} from "../interfaces/movement.interface";

export const reduceGroupedIncomesByCouchAndDate = (
  groupedIncomes: IncomesGrouped[],
  months: { month: number; year: number }[],
  propToReduce: keyof Omit<IncomesGrouped, "_id">
): ReduceIncomes[] => {
  const groupedAthletesCopy = [...groupedIncomes];

  const data: { [couchId: string]: ReduceIncomes } = {};

  groupedAthletesCopy.forEach((incomesGrouped, i) => {
    data[incomesGrouped._id.couchId] = {
      couchId: incomesGrouped._id.couchId,
      couchName: `${incomesGrouped._id.couchFirstName} ${incomesGrouped._id.couchLastName}`,
      calcDataByDate: data[incomesGrouped._id.couchId]?.calcDataByDate || {},
    };

    months.forEach((mon) => {
      const dateId = `${moment()
        .month(mon.month + 1)
        .format("MMMM")} ${mon.year}`;

      data[incomesGrouped._id.couchId].calcDataByDate[dateId] =
        mon.month + 1 === incomesGrouped._id.month &&
        mon.year === incomesGrouped._id.year
          ? (data[incomesGrouped._id.couchId].calcDataByDate[dateId] || 0) +
            incomesGrouped[propToReduce]
          : data[incomesGrouped._id.couchId].calcDataByDate[dateId] || 0;
    });
  });

  return Object.values(data);
};
