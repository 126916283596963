export enum ErrorMsg {
  Default = "¡Algo salió mal! 😥 Vuelve más tarde",
  UserNotFound = "Usuario no encontrado.",
  PasswordInvalid = "Contraseña incorrecta.",
  Unauthorized = "No tienes permisos para esta acción",
  Forbidden = "Tu tipo de usuario no puede realizar esta acción.",
  SessionExpired = "Tu sesión ha expirado. Por favor vuelve a loggearte.",
  AthletesFetch = "No se pudo consultar la información de los atletas. Contacta a soporte.",
  CouchesFetch = "No se pudo consultar la información de los couches. Contacta a soporte.",
  PlansFetch = "No se pudo consultar la información de los planes. Contacta a soporte.",
  IncomesFetch = "No se pudo consultar la información de los ingresos. Contacta a soporte.",
  AthleteNotSaved = "El atleta no se pudo guardar.",
  CouchNotSaved = "El couch no se pudo guardar.",
  PlanNotSaved = "El plan no se pudo guardar.",
  IncomeNotSaved = "El ingreso no se pudo guardar.",
  ExpenseNotSaved = "El gasto no se pudo guardar.",
  ExpiredToken = "Tu sesión ha expirado. Vuelve a iniciar sesión.",
  SessionNotFound = "Inicia sesión.",
  AthleteNotDeleted = "El atleta no se pudo remover.",
  CouchNotDeleted = "El couch no se pudo remover",
  MovementNotDeleted = "El movimiento no se pudo remover.",
  PlanNotDeleted = "El plan no se pudo remover.",
  NotFoundUser = "Usuario no encontrado.",
  SnapshotNotDeleted = "La snapshot no se pudo remover.",
  DuplicatedRecord = "Registro duplicado.",
}

export enum SuccessMsg {
  Default = "Todo salió bien.",
  AthleteSaved = "Atleta guardado exitosamente.",
  AthleteUpdated = "Atleta actualizado exitosamente.",
  CouchSaved = "Couch guardado exitosamente.",
  CouchUpdated = "Couch actualizado exitosamente.",
  PlanSaved = "Plan guardado exitosamente.",
  PlanUpdated = "Plan actualizado exitosamente.",
  IncomeSaved = "Ingreso guardado exitosamente.",
  IncomeUpdated = "Ingreso actualizado exitosamente.",
  PaymentSaved = "Pago guardado exitosamente.",
  PaymentUpdated = "Pago actualizado exitosamente.",
  ExpenseSaved = "Gasto guardado exitosamente.",
  ExpenseUpdated = "Gasto actualizado exitosamente.",
}

export enum InfoMsg {
  Default = "Información",
}

export enum WarningMsg {
  Default = "Ten cuidado. Algo puede salir mal",
  PlanDeleted = "Plan borrado exitosamente.",
  CouchDeleted = "Couch borrado exitosamente.",
  AthleteDeleted = "Atleta borrado exitosamente.",
  IncomeDeleted = "Ingreso borrado exitosamente.",
  PaymentDeleted = "Pago borrado exitoasmente.",
  ExpenseDeleted = "Gasto borrado exitosamente.",
  SnapshotDeleted = "Snapshot borrada exitosamente.",
}

export enum ToastSeverity {
  Success,
  Info,
  Warning,
  Error,
}

export enum AlertColor {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}
