import { useState, useEffect, useCallback, useMemo } from "react";
import { useAppSelector } from "../../../hooks";
import { useToasts } from "../../../hooks/useToasts";
import {
  IncomesGrouped,
  ReduceIncomes,
} from "../../../interfaces/movement.interface";
import { getIncomesByDateAndCouch } from "../../../helpers/movement";
import moment from "moment";
import { MetadataTable } from "../../../interfaces/common";
import { Grid } from "@mui/material";
import { SimpleTable } from "../../layouts/SimpleTable";
import { AppWidgetSummary } from "../atoms/AppWidgetSummary";
import { reduceGroupedIncomesByCouchAndDate } from "../../../utils/reduceGroupedIncomes";

interface Props {
  months: { month: number; year: number }[];
  fromDate: number;
  toDate: number;
}

export const IncomesGroupedByCouchAndDate: React.FC<Props> = ({
  months,
  fromDate,
  toDate,
}) => {
  const { statusErrorMdw } = useToasts();
  const { token } = useAppSelector((state) => state.session);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedIncomes, setGroupedIncomes] = useState<IncomesGrouped[]>([]);
  const reduceGroupedIncomesByTotalIncome: ReduceIncomes[] = useMemo(
    () =>
      reduceGroupedIncomesByCouchAndDate(
        groupedIncomes,
        months,
        "totalIncomes"
      ),
    [months, groupedIncomes]
  );
  const reduceGroupedIncomesByAvgTicket: ReduceIncomes[] = useMemo(
    () =>
      reduceGroupedIncomesByCouchAndDate(
        groupedIncomes,
        months,
        "avgTotalTicket"
      ),
    [months, groupedIncomes]
  );
  const reduceGroupedIncomesByCount: ReduceIncomes[] = useMemo(
    () =>
      reduceGroupedIncomesByCouchAndDate(
        groupedIncomes,
        months,
        "incomesCount"
      ),
    [months, groupedIncomes]
  );

  const fetchGroupedIncomes = useCallback(
    async (token: string) => {
      setIsLoading(true);
      try {
        const data = await getIncomesByDateAndCouch(
          { fromDate, toDate },
          token
        );
        setGroupedIncomes(data);
      } catch (e: any) {
        statusErrorMdw(e);
      } finally {
        setIsLoading(false);
      }
    },
    [fromDate, toDate, token]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchGroupedIncomes(token);
  }, [token]);

  const metadata: ({
    isPrice,
  }: {
    isPrice: boolean;
  }) => MetadataTable<ReduceIncomes> = useCallback(
    ({ isPrice = false }) => ({
      columns: [
        {
          name: "couchName",
          label: "Couch",
        },
        ...months.map((mon) => {
          const dateId = `${moment()
            .month(mon.month + 1)
            .format("MMMM")} ${mon.year}`;

          return {
            name: dateId,
            label: `${moment().month(mon.month).format("MMMM")} ${mon.year}`,
            compute: (arg?: ReduceIncomes) => {
              if (!arg?.calcDataByDate[dateId]) {
                return 0;
              }

              if (isPrice) {
                return `$ ${arg?.calcDataByDate[dateId].toFixed(2)}`;
              }
              return arg?.calcDataByDate[dateId];
            },
          };
        }),
      ],
    }),
    [months]
  );

  return (
    <>
      <Grid md={12} item>
        <AppWidgetSummary
          title="Ingresos por mes por couch"
          subtitle="80% del total del ingreso. Los couches sin ingresos no se muestran."
        >
          <SimpleTable
            entityName="Ingresos agrupados por couch y fecha"
            metadata={metadata({ isPrice: true })}
            data={reduceGroupedIncomesByTotalIncome}
            loading={isLoading}
          />
        </AppWidgetSummary>
      </Grid>
      <Grid md={12} item>
        <AppWidgetSummary
          title="Ticket promedio por couch"
          subtitle="El promedio se calcula del total del ticket. Los couches sin ingresos no se muestran."
        >
          <SimpleTable
            entityName="Ingresos agrupados por couch y fecha"
            metadata={metadata({ isPrice: true })}
            data={reduceGroupedIncomesByAvgTicket}
            loading={isLoading}
          />
        </AppWidgetSummary>
      </Grid>
      <Grid md={12} item>
        <AppWidgetSummary
          title="Número de ingresos por couch"
          subtitle="Los couches sin ingresos no se muestran."
        >
          <SimpleTable
            entityName="Ingresos agrupados por couch y fecha"
            metadata={metadata({ isPrice: false })}
            data={reduceGroupedIncomesByCount}
            loading={isLoading}
          />
        </AppWidgetSummary>
      </Grid>
    </>
  );
};
