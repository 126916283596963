import { useDispatch } from "react-redux";
import { addNewToast } from "../features/toast/toast.slice";
import { ErrorMsg, ToastSeverity } from "../enum/toast.enum";
import {
  SuccessToast,
  ErrorToast,
  WarningToast,
  InfoToast,
  CommonToast,
} from "../interfaces/toast.intarface";
import { AxiosError } from "axios";

export const useToasts = () => {
  const dispatch = useDispatch();

  const dispatchToast = (
    toast: (SuccessToast | ErrorToast | WarningToast | InfoToast) & CommonToast
  ) => {
    dispatch(addNewToast(toast));
  };

  const statusErrorMdw = (
    error: AxiosError,
    specificToast?: {
      message: ErrorMsg;
      statusCode?: number;
    }
  ) => {
    const statusCode = error.status || error.response?.status || 500;
    const severity = ToastSeverity.Error;
    if (specificToast?.message && !specificToast.statusCode) {
      dispatchToast({ severity, message: specificToast.message });
      return;
    }

    if (specificToast?.statusCode === statusCode) {
      dispatchToast({ ...specificToast, severity });
      return;
    }
    switch (statusCode) {
      case 401:
        dispatchToast({ severity, message: ErrorMsg.Unauthorized });
        break;
      case 403:
        dispatchToast({ severity, message: ErrorMsg.Unauthorized });
        break;
      case 404:
        dispatchToast({ severity, message: ErrorMsg.NotFoundUser });
        break;
      case 409:
        dispatchToast({ severity, message: ErrorMsg.DuplicatedRecord });
        break;
      default:
        dispatchToast({ severity, message: ErrorMsg.Default });
        break;
    }
  };

  return { statusErrorMdw, dispatchToast };
};
