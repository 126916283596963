import { Card, Stack, Box, Typography, SxProps, Theme } from "@mui/material";
import React, { ReactNode } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";

interface Props {
  title?: string;
  subtitle?: string;
  total?: number;
  nonPriceNumber?: number;
  icon?: ReactNode;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}
export const AppWidgetSummary: React.FC<Props> = ({
  title,
  subtitle,
  nonPriceNumber,
  total,
  icon,
  sx,
  children,
}) => {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      sx={{
        p: 1,
        py: 3,
        borderRadius: 2,
        display: "block",
        ...sx,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Stack
          spacing={0.5}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
          }}
        >
          {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}
          <Box
            sx={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            {title && <Typography variant="h4">{title}</Typography>}
            {total && (
              <Typography variant="h4">
                {"$" + fShortenNumber(total)}
              </Typography>
            )}
            {nonPriceNumber && (
              <Typography variant="h4">{nonPriceNumber}</Typography>
            )}
            {subtitle && (
              <Typography variant="subtitle2" sx={{ color: "text.disabled" }}>
                {subtitle}
              </Typography>
            )}
          </Box>
        </Stack>
        {children && <Box sx={{ margin: "auto" }}>{children}</Box>}
      </Box>
    </Card>
  );
};
