import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Athlete, UpdateAthlete } from "../../interfaces/athlete.interface";
import { LoadingEnum } from "../../enum/common";
import { GetParams } from "../../interfaces/common";
import { getAthletes } from "../../helpers/athletes";

interface AthletesState {
  athletesList: Athlete[];
  loading: LoadingEnum;
}

const initialState: AthletesState = {
  athletesList: [],
  loading: LoadingEnum.Idle,
};

export const getAthletesList = createAsyncThunk(
  "athletes/get",
  async ({ params, token }: { params: GetParams<Athlete>; token: string }) =>
    await getAthletes(params, token)
);

export const athletesSlice = createSlice({
  name: "athletes",
  initialState,
  reducers: {
    addAthlete: (state: AthletesState, action: PayloadAction<Athlete>) => {
      const newList = [...state.athletesList, action.payload].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      state.athletesList = newList;
    },
    updateAthlete: (
      state: AthletesState,
      action: PayloadAction<UpdateAthlete>
    ) => {
      const { _id } = action.payload;

      const index = state.athletesList.findIndex((at) => at._id === _id);

      if (index === -1) {
        return;
      }

      state.athletesList[index] = {
        ...state.athletesList[index],
        ...action.payload,
      };
    },
    deleteAthlete: (
      state: AthletesState,
      action: PayloadAction<{ id: string }>
    ) => {
      const { id } = action.payload;

      const index = state.athletesList.findIndex((at) => at._id === id);

      if (index === -1) {
        return;
      }

      state.athletesList.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder.addCase(getAthletesList.pending, (state: AthletesState) => {
      state.loading = LoadingEnum.Pending;
    });
    builder.addCase(
      getAthletesList.fulfilled,
      (state: AthletesState, action: PayloadAction<Athlete[]>) => {
        state.athletesList = action.payload;
        state.loading = LoadingEnum.Success;
      }
    );
    builder.addCase(getAthletesList.rejected, (state: AthletesState) => {
      state.loading = LoadingEnum.Error;
    });
  },
});

export default athletesSlice.reducer;

export const { addAthlete, updateAthlete, deleteAthlete } =
  athletesSlice.actions;
