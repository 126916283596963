import { Divider, Grid, RegularBreakpoints, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AthletesGroupedByCouchAndPlan } from "../../../interfaces/athlete.interface";
import { getAthletesGroupedByCouchesAndPlans } from "../../../helpers/athletes";
import { useAppSelector } from "../../../hooks";
import { useToasts } from "../../../hooks/useToasts";
import { MetadataTable } from "../../../interfaces/common";
import { SimpleTable } from "../../layouts/SimpleTable";
import { AppWidgetSummary } from "../atoms/AppWidgetSummary";

interface Props {
  defaultGroupedAthletes?: AthletesGroupedByCouchAndPlan[];
  childrenBreakpoints?: RegularBreakpoints;
}
export const AthletesGroupedTables: React.FC<Props> = ({
  defaultGroupedAthletes,
  childrenBreakpoints,
}) => {
  const { statusErrorMdw } = useToasts();
  const { token } = useAppSelector((state) => state.session);
  const { couchesList: couches } = useAppSelector((state) => state.couches);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedAthletes, setGroupedAthletes] = useState<
    AthletesGroupedByCouchAndPlan[]
  >(defaultGroupedAthletes || []);

  const reduceGroupedAthletesByCouch: AthletesGroupedByCouchAndPlan[] =
    useMemo(() => {
      const object: { [couchId: string]: AthletesGroupedByCouchAndPlan } = {};

      for (let i = 0; i < groupedAthletes.length; i++) {
        const group = groupedAthletes[i];

        object[group._id.couchId] = {
          ...group,
          actives: (object[group._id.couchId]?.actives || 0) + group.actives,
          inactives:
            (object[group._id.couchId]?.inactives || 0) + group.inactives,
          pendings: (object[group._id.couchId]?.pendings || 0) + group.pendings,
          lates: (object[group._id.couchId]?.lates || 0) + group.lates,
        };
      }

      return Object.values(object);
    }, [groupedAthletes]);

  const reduceGroupedAthletesByPlan: AthletesGroupedByCouchAndPlan[] =
    useMemo(() => {
      const object: { [planId: string]: AthletesGroupedByCouchAndPlan } = {};

      for (let i = 0; i < groupedAthletes.length; i++) {
        const group = groupedAthletes[i];

        object[group._id.planId] = {
          ...group,
          actives: (object[group._id.planId]?.actives || 0) + group.actives,
          inactives:
            (object[group._id.planId]?.inactives || 0) + group.inactives,
          pendings: (object[group._id.planId]?.pendings || 0) + group.pendings,
          lates: (object[group._id.couchId]?.lates || 0) + group.lates,
        };
      }

      return Object.values(object);
    }, [groupedAthletes]);

  const fetchGroupedAthletes = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getAthletesGroupedByCouchesAndPlans(token);
      setGroupedAthletes(data);
    } catch (e: any) {
      statusErrorMdw(e);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (defaultGroupedAthletes) {
      return;
    }
    fetchGroupedAthletes();
  }, []);

  const metadata: MetadataTable<AthletesGroupedByCouchAndPlan> = useMemo(
    () => ({
      columns: [
        {
          name: "couch",
          label: "Couch",
          compute(arg) {
            return `${arg?._id?.couchFirstName} ${arg?._id?.couchLastName}`;
          },
        },
        {
          name: "actives",
          label: "Activos",
        },
        {
          name: "inactives",
          label: "Inactivos",
        },
        {
          name: "pendings",
          label: "Pendientes",
        },
      ],
    }),
    []
  );

  const metadataPlans: MetadataTable<AthletesGroupedByCouchAndPlan> = useMemo(
    () => ({
      columns: [
        {
          name: "plan",
          label: "Plan",
          compute(arg) {
            return arg?._id?.planName || "No name";
          },
        },
        {
          name: "actives",
          label: "Activos",
        },
        {
          name: "inactives",
          label: "Inactivos",
        },
        {
          name: "pendings",
          label: "Pendientes",
        },
      ],
    }),
    []
  );

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid sm={12} md={12} {...childrenBreakpoints} item>
          <AppWidgetSummary
            title={`Atletas por couch (${couches.length})`}
            subtitle="Los couches sin atletas no se muestran"
          >
            <SimpleTable
              entityName="Atletas agrupados"
              metadata={metadata}
              data={reduceGroupedAthletesByCouch}
              loading={isLoading}
            />
          </AppWidgetSummary>
        </Grid>
        <Grid sm={12} md={12} {...childrenBreakpoints} item>
          <AppWidgetSummary
            title="Atletas por plan"
            subtitle="Los planes sin atletas no se muestran"
          >
            <SimpleTable
              entityName="Atletas agrupados"
              metadata={metadataPlans}
              data={reduceGroupedAthletesByPlan}
              loading={isLoading}
            />
          </AppWidgetSummary>
        </Grid>
      </Grid>
    </Grid>
  );
};
