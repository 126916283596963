import axios from "axios";
import { GetParams } from "../interfaces/common";
import {
  Athlete,
  AthletesGroupedByCouchAndPlan,
  CreateAthlete,
  UpdateAthlete,
} from "../interfaces/athlete.interface";
import { setToken } from "../utils/setToken";

export const getAthletes = async (
  params: GetParams<Athlete>,
  token: string
): Promise<Athlete[]> =>
  (await axios.get("/api/v1/athlete", { params, ...setToken(token) })).data;

export const saveAthlete = async (
  data: CreateAthlete,
  token: string
): Promise<string> =>
  (await axios.post("/api/v1/athlete", data, setToken(token))).data;

export const patchAthlete = async (
  data: UpdateAthlete,
  token: string
): Promise<string> =>
  (await axios.patch(`/api/v1/athlete/${data._id}`, data, setToken(token))).data;

export const getAthleteById = async (
  id: string,
  token: string
): Promise<Athlete> =>
  (await axios.get(`/api/v1/athlete/${id}`, setToken(token))).data;

export const getAthletesGroupedByCouchesAndPlans = async (
  token: string
): Promise<AthletesGroupedByCouchAndPlan[]> =>
  (await axios.get("/api/v1/athlete/groupedByCouchesAndPlans", setToken(token)))
    .data;

export const deleteAthleteById = async (id: string, token: string) =>
  (await axios.delete(`/api/v1/athlete/${id}`, setToken(token))).data;
