import React from "react";
import { MetadataInput } from "../../../interfaces/common";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export const InputField: React.FC<MetadataInput> = ({
  inputType = "text",
  handleChange,
  handleSelect,
  handleChangeDate,
  onError,
  value,
  variant,
  required = true,
  fullWidth = true,
  autoFocus = true,
  gridSize,
  disabled = false,
  menuItems,
  options,
  handleChangeAutocomplete,
  disablePortal,
  ...props
}) => {
  if (inputType === "select" && menuItems) {
    const { label, id } = props;
    return (
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel id={`label-select-${label}-${id}`}>{label}</InputLabel>
        <Select
          fullWidth
          variant={variant}
          margin="dense"
          autoFocus={autoFocus}
          labelId={`label-select-${label}-${id}`}
          label={label}
          onChange={handleSelect}
          name={props.name}
          value={value}
          data-testid={`input-select-${id}`}
          disabled={disabled}
        >
          {menuItems.length > 0 ? (
            menuItems.map(({ label, value }, i) => (
              <MenuItem value={value} key={`menu-item-${value}-${id}`}>
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={""}>
              Necesitas al menos un(a) {label} para continuar
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

  if (inputType === "date") {
    return (
      <FormControl fullWidth={fullWidth} required={required}>
        <DatePicker
          {...props}
          autoFocus={autoFocus}
          disabled={disabled}
          value={moment(value)}
          onChange={(v, c, name = props.name) => handleChangeDate?.(v, c, name)}
          data-testid={`input-date-${props.id}`}
        />
      </FormControl>
    );
  }

  if (new RegExp(/(price|amount|profits)$/gi).test(String(props.name))) {
    const { label, id } = props;
    return (
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel htmlFor={`label-select-${label}-${id}`}>
          {props.label}
        </InputLabel>
        <OutlinedInput
          id={id}
          name={props.name}
          margin="dense"
          autoFocus={autoFocus}
          onChange={handleChange}
          disabled={disabled}
          value={value}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Amount"
          data-testid={`input-outlined-${id}`}
        />
      </FormControl>
    );
  }

  if (inputType === "autocomplete" && options) {
    return (
      <Autocomplete
        fullWidth
        autoComplete
        onChange={handleChangeAutocomplete}
        disablePortal={disablePortal}
        isOptionEqualToValue={(option, value) => {
          if (!value.label || !value.value) {
            return true;
          }

          if (!new RegExp(/[a-zA-Z]/g).test(JSON.stringify(value.value))) {
            return true;
          }

          return JSON.stringify(option.value) === JSON.stringify(value.value);
        }}
        id={props.id}
        options={options}
        value={value}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    );
  }

  return (
    <TextField
      {...props}
      value={value}
      required={required}
      autoFocus={autoFocus}
      onChange={handleChange}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      data-testid={`input-text-${props.id}`}
    />
  );
};
