import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useAppSelector } from "../../../hooks";
import { useToasts } from "../../../hooks/useToasts";
import {
  ProfitGrouped,
  SalaryGrouped,
} from "../../../interfaces/movement.interface";
import { AppWidgetSummary } from "../atoms/AppWidgetSummary";
import { AthletesGroupedTables } from "./AthletesGroupedTables";
import { IncomesGroupedByCouchAndDate } from "./IncomesGroupedByCouchAndDate";
import { getSalaries } from "../../../helpers/movement";
import { GetSalaryParams } from "../../../interfaces/common";
import { LoadingEnum } from "../../../enum/common";

type TotalTypes = "total" | "trackfit" | "couches";

export const MONTHS_BEFORE_NOW = 12;

interface Props {
  profits: ProfitGrouped[];
  dateRange: { fromDate: number; toDate: number };
  setLoading: Dispatch<SetStateAction<boolean>>;
  months: { month: number; year: number }[];
}
const DashboardAdminSection: React.FC<Props> = ({
  profits,
  dateRange,
  setLoading,
  months,
}) => {
  const { statusErrorMdw } = useToasts();
  const { token } = useAppSelector((state) => state.session);
  const [salaries, setSalaries] = useState<SalaryGrouped[]>([]);
  const [resume, setResume] = useState<{ [key in TotalTypes]: number }>();
  const [salaryTotal, setSalaryTotal] = useState(0);
  const [accountTotal, setAccountTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);
  const { expensesList: expenses, expensesLoading } = useAppSelector(
    (state) => state.movements
  );

  const fetchSalaries = useCallback(
    async (params: GetSalaryParams, token: string) => {
      setLoading(true);
      try {
        const data = await getSalaries(params, token);
        setSalaries(data);
      } catch (e: any) {
        statusErrorMdw(e);
      } finally {
        setLoading(false);
      }
    },
    [statusErrorMdw]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchSalaries({ ...dateRange }, token);
  }, [dateRange, token]);

  useEffect(() => {
    setResume(
      profits.reduce(
        (res, profit) => {
          res["total"] = res["total"] + profit.profitsTotal;
          res["couches"] = res["couches"] + profit.couchTotal;
          res["trackfit"] = res["trackfit"] + profit.trackfitTotal;

          return res;
        },
        { total: 0, trackfit: 0, couches: 0 }
      )
    );
  }, [profits]);

  useEffect(() => {
    setSalaryTotal(salaries.reduce((result, sal) => result + sal.total, 0));
  }, [salaries]);

  useEffect(() => {
    if (resume?.trackfit === undefined) {
      return;
    }
    setAccountTotal(resume.trackfit - salaryTotal - expensesTotal);
  }, [salaryTotal, expensesTotal, resume?.trackfit]);

  useEffect(() => {
    if (expensesLoading !== LoadingEnum.Success) {
      return;
    }
    setExpensesTotal(expenses.reduce((prev, curr) => prev + curr.amount, 0));
  }, [expenses]);

  return (
    <>
      <Grid xs={12} sm={6} md={3} item>
        <AppWidgetSummary
          subtitle="Balance en cuenta de trackfit"
          total={accountTotal}
          icon={<img src="/favicon.png" alt="trackfit-icon" />}
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} item>
        <AppWidgetSummary
          subtitle="Ingresos para trackfit"
          total={resume?.trackfit}
          icon={
            <Icon
              icon={"tabler:sum"}
              width={"100%"}
              color="rgb(44,188,156,0.8)"
            />
          }
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} item>
        <AppWidgetSummary
          subtitle={"Ingresos para couches"}
          total={resume?.couches}
          icon={
            <Icon
              icon={"fluent:person-money-20-filled"}
              width={"100%"}
              color="rgb(44,188,156,0.8)"
            />
          }
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} item>
        <AppWidgetSummary
          subtitle="Total gastos en general"
          total={expensesTotal}
          icon={
            <Icon
              icon={"arcticons:bbc-sport"}
              width={"100%"}
              color="rgb(44,188,156,0.8)"
            />
          }
        />
      </Grid>
      <Grid xs={12} sm={6} md={3} item>
        <AppWidgetSummary
          subtitle="Total pagado en salarios"
          total={salaryTotal}
          icon={
            <Icon
              icon={"streamline:payment-10"}
              width={"100%"}
              color="rgb(44,188,156,0.8)"
            />
          }
        />
      </Grid>
      <IncomesGroupedByCouchAndDate months={months} {...dateRange} />
      <AthletesGroupedTables />
    </>
  );
};

export default DashboardAdminSection;
