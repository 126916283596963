export enum LoadingEnum {
  Idle,
  Pending,
  Success,
  Error,
}

export enum SuscriptionStatus {
  Active,
  Inactive,
  PendingPayment,
  Late
}

export enum SuscriptionStatusSpanish {
  Activo,
  Baja,
  Pendiente,
  Atrasado
}

export enum CouchStatus {
  Active,
  Inactive,
}

export enum OrderEnum {
  "ASC" = 1,
  "DESC" = -1,
}

export enum MovementType {
  Income,
  Expense,
  Salary,
}

export enum PaymentPeriod {
  Day,
  Week,
  Month,
  Year,
}

export enum MomentPeriod {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum PaymentPeriodSpanish {
  Day = "Día",
  Week = "Semana",
  Month = "Mes",
  Year = "Año",
}

export enum UserRole {
  Admin,
  Manager,
  Couch,
}
