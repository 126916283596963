import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingEnum } from "../../enum/common";
import { Couch, UpdateCouch } from "../../interfaces/couch.interface";
import { GetParams } from "../../interfaces/common";
import { getCouches } from "../../helpers/couch";

interface CouchesState {
  couchesList: Couch[];
  loading: LoadingEnum;
}

const initialState: CouchesState = {
  couchesList: [],
  loading: LoadingEnum.Idle,
};

export const getCouchesList = createAsyncThunk(
  "couches/get",
  async ({ params, token }: { params: GetParams<Couch>; token: string }) =>
    (await getCouches(params, token)).data
);

export const couchesSlice = createSlice({
  name: "couches",
  initialState,
  reducers: {
    addCouch: (state: CouchesState, action: PayloadAction<Couch>) => {
      const newList = [...state.couchesList, action.payload].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      state.couchesList = newList;
    },
    updateCouch: (state: CouchesState, action: PayloadAction<UpdateCouch>) => {
      const { _id } = action.payload;

      const index = state.couchesList.findIndex((at) => at._id === _id);

      if (index === -1) {
        return;
      }

      state.couchesList[index] = {
        ...state.couchesList[index],
        ...action.payload,
      };
    },
    deleteCouch: (
      state: CouchesState,
      action: PayloadAction<{ id: string }>
    ) => {
      const { id } = action.payload;

      const index = state.couchesList.findIndex((at) => at._id === id);

      if (index === -1) {
        return;
      }

      state.couchesList.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder.addCase(getCouchesList.pending, (state: CouchesState) => {
      state.loading = LoadingEnum.Pending;
    });
    builder.addCase(
      getCouchesList.fulfilled,
      (state: CouchesState, action: PayloadAction<Couch[]>) => {
        state.couchesList = action.payload;
        state.loading = LoadingEnum.Success;
      }
    );
    builder.addCase(getCouchesList.rejected, (state: CouchesState) => {
      state.loading = LoadingEnum.Error;
    });
  },
});

export default couchesSlice.reducer;

export const { addCouch, updateCouch, deleteCouch } = couchesSlice.actions;
