import {
  IconButton,
  alpha,
  Avatar,
  Popover,
  Box,
  Typography,
  Divider,
  MenuItem,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import avatar from "../../../assets/images/avatars/avatar_25.jpg";
import { useAppSelector } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteSession } from "../../../features/session/session.slice";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    nav: "/",
  } /* 
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
  }, */,
];

export const AccountPopover = () => {
  const nav = useNavigate();
  const [open, setOpen] = useState<Element | null>(null);
  const { user } = useAppSelector((state) => state.session);
  const dispatch = useDispatch();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={avatar}
          alt={"Avatar"}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.firstName.split(" ")[0]}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              nav(option.nav);
            }}
          >
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={() => {
            handleClose();
            dispatch(deleteSession());
            nav("/login");
          }}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};
