import moment from "moment";

export const getMonthsArrayFromTo = (
  fromDate: number,
  toDate: number
): { month: number; year: number }[] => {
  const arr: { month: number; year: number }[] = [];

  const monthsDifference = moment(toDate).diff(fromDate, "months");

  for (let i = 0; i <= monthsDifference; i++) {
    const newDate = moment(fromDate).add(i, "months");
    let year = newDate.get("year");
    let month = newDate.get("month"); // mongo aggregation considers January as month 1
    arr.push({ month, year });
  }

  return arr;
};
