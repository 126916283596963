import axios from "axios";
import { DeleteResult, GetParams } from "../interfaces/common";
import { Couch, CreateCouch, UpdateCouch } from "../interfaces/couch.interface";
import { setToken } from "../utils/setToken";

export const getCouches = async (params: GetParams<Couch>, token: string) => {
  return await axios.get("/api/v1/couch", { params, ...setToken(token) });
};

export const saveCouch = async (
  data: CreateCouch,
  token: string
): Promise<Couch> =>
  (await axios.post("/api/v1/couch", data, setToken(token))).data;

export const getCouchById = async (id: string, token: string): Promise<Couch> =>
  (await axios.get(`/api/v1/couch/${id}`, setToken(token))).data;

export const patchCouch = async (
  data: UpdateCouch,
  token: string
): Promise<string> =>
  (await axios.patch(`/api/v1/couch/${data._id}`, data, setToken(token))).data;

export const deleteCouchById = async (
  id: string,
  token: string
): Promise<DeleteResult> =>
  (await axios.delete(`/api/v1/couch/${id}`, setToken(token))).data;
