import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingEnum } from "../../enum/common";
import { User } from "../../interfaces/user.interface";
import { getProfileInfo } from "../../helpers/session";
import moment from "moment";

interface SessionState {
  user?: User;
  loading: LoadingEnum;
  token: string;
  expiredToken: boolean;
}

const initialState: SessionState = {
  loading: LoadingEnum.Idle,
  expiredToken: false,
  user: undefined,
  token: "",
};

export const getProfile = createAsyncThunk(
  "get/profile",
  async (token: string) => await getProfileInfo(token)
);

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    saveToken: (
      state: SessionState,
      action: PayloadAction<{ access_token: string }>
    ) => {
      const { access_token } = action.payload;
      state.token = access_token;
      window.localStorage.setItem("access_token", access_token);
    },
    deleteSession: (state: SessionState) => {
      state.user = undefined;
      state.token = "";
      state.loading = LoadingEnum.Idle;
      localStorage.removeItem("access_token");
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfile.pending, (state: SessionState) => {
      state.loading = LoadingEnum.Pending;
    });
    builder.addCase(
      getProfile.fulfilled,
      (state: SessionState, action: PayloadAction<User>) => {
        const { exp } = action.payload;

        if (exp < moment().unix()) {
          state.expiredToken = true;
          state.loading = LoadingEnum.Success;
          return;
        }
        state.expiredToken = false;

        state.loading = LoadingEnum.Success;
        state.user = action.payload;
      }
    );
    builder.addCase(getProfile.rejected, (state: SessionState, action) => {
      if (action.error.message && /401/.test(action.error.message)) {
        state.expiredToken = true;
      }
      state.loading = LoadingEnum.Error;
    });
  },
});

export default sessionSlice.reducer;

export const { saveToken, deleteSession } = sessionSlice.actions;
