export const COLORS = {
  green: {
    hex: "#2cbc9c",
    rgb: "rgb(44,188,156)",
  },
  lightGreen: {
    hex: "#c8ede6",
    rgb: "rgb(200,237,230)"
  },
  blue: {
    hex: "#0c3c6c",
    rgb: "rgb(12,60,108)"
  },
  darkblue: {
    hex: "#144f74",
    rgb: "rgb(20,79,116)"
  },

};
