import "./App.css";
import "moment/locale/es";
import ThemeProvider from "./theme";
import CustomRouter from "./routes/Sections";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ToastProvider } from "./components/layouts/ToastProvider";
import { SessionProvider } from "./components/layouts/SessionProvider";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"es"}>
          <ToastProvider>
            <BrowserRouter>
              <SessionProvider>
                <CustomRouter />
              </SessionProvider>
            </BrowserRouter>
          </ToastProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
