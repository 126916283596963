import axios from "axios";
import { LoginBody } from "../interfaces/session.interface";
import { User } from "../interfaces/user.interface";
import { setToken } from "../utils/setToken";

export const login = async (data: LoginBody) => {
  return (await axios.post("/api/v1/auth/login", data));
};

export const getProfileInfo = async (token: string): Promise<User> => {
  return (await axios.get("/api/v1/profile", setToken(token))).data;
};
