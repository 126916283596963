import { Box, Typography } from "@mui/material";
import runner from "../../../assets/runner.gif";

export const RunnerLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "2rem", fontWeight: 500 }}>
          Loading...
        </Typography>
        <img src={runner} alt="loader" />
      </Box>
    </Box>
  );
};
