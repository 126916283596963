import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ labels, info }) => {
  return (
    <Doughnut
      data={{
        labels,
        datasets: [
          {
            data: info,
            backgroundColor: [
              "rgba(44,188,156, 0.4)",
              "rgba(255, 99, 132, 0.4)",
              "rgba(255, 206, 86, 0.4)",
              "rgba(213, 214, 170, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(255, 159, 64, 0.4)",
            ],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};
