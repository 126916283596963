import { configureStore } from "@reduxjs/toolkit";
import athletesReducer from "../features/athletes/athletes.slice";
import couchesReducer from "../features/couches/couches.slice";
import plansReducer from "../features/plans/plans.slice";
import toastReducer from "../features/toast/toast.slice";
import sessionReducer from "../features/session/session.slice";
import movementReducer from "../features/movements/movement.slice";

export const store = configureStore({
  reducer: {
    athletes: athletesReducer,
    couches: couchesReducer,
    plans: plansReducer,
    toasts: toastReducer,
    session: sessionReducer,
    movements: movementReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
