import { ReactNode, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { removeToast } from "../../features/toast/toast.slice";
import { AlertColor, ToastSeverity } from "../../enum/toast.enum";

interface Props {
  children: ReactNode;
}

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { toasts } = useAppSelector((state) => state.toasts);

  const handleClose = useCallback(
    (id?: string, reason?: SnackbarCloseReason) => {
      if (!id || reason === "clickaway") {
        return;
      }

      dispatch(removeToast(id));
    },
    [dispatch]
  );

  return (
    <>
      {children}
      {toasts.map((toast) => (
        <Snackbar
          open
          key={`toast-${toast.id}`}
          autoHideDuration={toast.interval || 5000}
          onClose={(e, r) => handleClose(toast.id, r)}
          onClick={toast.onClick}
        >
          <Alert
            variant="filled"
            severity={
              AlertColor[
                ToastSeverity[toast.severity] as keyof typeof AlertColor
              ]
            }
            onClose={() => handleClose(toast.id)}
            sx={{ width: "100%" }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
